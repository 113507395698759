import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

  const shopifyProductId = '6128062824615'
  const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

  const sizes = {
    "Length": [
      "",
      "23 1/4 / 59",
      "23 1/2 / 60",
      "24 / 61",
      "24 1/2 62",
      "",
      "",
      "",
      ""
    ],
    "Waist": [
      "",
      "26 / 66",
      "27 1/2 / 70",
      "29 / 74",
      "30 3/4 / 78",
      "",
      "",
      "",
      ""
    ],
    "Hip": [
      "",
      "32 1/4 / 82",
      "33 3/4 / 86",
      "35 1/2 / 90",
      "37 / 94",
      "",
      "",
      "",
      ""
    ],
    "Slit": [
      "",
      "13 3/4 / 35",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  }

  return (
    <Layout>
      <SEO title="Shop - Skirt" />

      <ProductV2 productId={base64ProductId} sizes={sizes} />
    </Layout>
  )

} export default ProductShopify